import React from 'react';
import ReactDOM from 'react-dom/client';
import Typed from 'typed.js';
import './index.css';

import reportWebVitals from './reportWebVitals';

const map = ReactDOM.createRoot(document.getElementById('map'));
map.render(
  <img
    src={`https://maps.googleapis.com/maps/api/staticmap?center=London,UK&zoom=8&size=300x240&key=${process.env.REACT_APP_GMAP_API_KEY}`}
    alt="map"
    align="left"
    padding="5px"
  />
);

const TypedTagline = () => {

  // Create reference to store the DOM element containing the animation
  const el = React.useRef(null);
  // Create reference to store the Typed instance itself
  const typed = React.useRef(null);

  React.useEffect(() => {
    const options = {
      strings: [
        "I was a former full-stack web developer.",
        "I co-founded a healthcare social enterprise in 2015.",
        "I now work as a digital messaging specialist.",
        "I'm also a freelance translator.",
        "I'm Tung, nice to meet you!"
      ],
      typeSpeed: 35,
      backSpeed: 20,
      smartBackspace: true,
      cursorChar: " ▌ ",
      startDelay: 1500,
      backDelay: 1000,
    };

    // elRef refers to the <span> rendered below
    typed.current = new Typed(el.current, options);

    return () => {
      // Make sure to destroy Typed instance during cleanup
      // to prevent memory leaks
      typed.current.destroy();
    }
  }, [])

  return (
    <div className="terminal">
      <span className="command" ref={el} />
    </div>
  );

}

const tagline = ReactDOM.createRoot(document.getElementById('tagline'));
tagline.render(
  <TypedTagline />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals 
reportWebVitals();

